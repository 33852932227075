import { useState, useEffect } from "react";
import DataTable from "../../Common/DataTable/DataTable";
import { Modal } from 'react-bootstrap';
import axios from "axios";
import { useSelector } from "react-redux";

const Actions = () => {
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [logs, setLogs] = useState([]);
    const [total, setTotal] = useState(0);
    const [keyword, setKeyword] = useState("");
    const [notification, setNotification] = useState({ show: false, title: "", body: "", button: "" });
    const [bidType, setBidType] = useState("last-bid");
    const [active, setActive] = useState({});
    const [date, setDate] = useState({ from: "", to: "" });
    const admin = useSelector((state) => state.user);


    const headers = ["Lot", "Username", "Bid", "Type",  "Date", ""];
    const showParams = ["lot", "username", "bid", "action", "date"];

    const handlers = {
        search(keyword) {
            setKeyword(keyword);
        }
    }

    const selects = [
        {
            name: "Bid type",
            id: 2001,
            label: "",
            options: [{ label: "All bids", value: 'all-bids', id: 3001 }, { label: "Last bid", value: "last-bid", id: 3002 }],
            action: (value) => {
                setBidType(value);
            }
        }
    ]

    const rowActions = [
        {
            name: "Event Target",
            id: 1002,
            action: (car) => {
                console.log(car.button)
            }
        }
    ]

    useEffect(() => {
        axios(`/api/auto-bids/get-logs?page=${page}&limit=${limit}&keyword=${keyword}`)
            .then((response) => {
                const data = response.data.data;
                setLogs([...data]);
                setTotal(response.data.total)
            })
            .catch((err) => {
                setNotification({ show: true, title: "An error has been detected", body: "Something went wrong", button: "Ok" })
            })
    }, [limit, page, keyword, bidType, date])

    return (
        <>
            <DataTable
                withoutSearchButton={true}
                headers={headers}
                searchHandler={handlers.search}
                title={"Actions"}
                limit={limit}
                setLimit={setLimit}
                limitControl={true}
                actionButtons={[]}
                data={logs}
                showParams={showParams}
                pageChanged={page}
                setPageChanged={setPage}
                total={total}
                rowActions={true}
                rowActionsList={rowActions}
                withSelectFilter={false}
                setActiveRow={setActive}
                selects={selects}
                dateRange={true}
                setDate={setDate}
                selectValue={bidType}
            />

            <Modal show={notification.show} onHide={() => setNotification((prev) => ({ ...prev, show: false }))}>
                <Modal.Header><h4>{notification.title}</h4></Modal.Header>
                <Modal.Body>{notification.body}</Modal.Body>
                <Modal.Footer>
                    <button className={"blue-button"} onClick={() => setNotification((prev) => ({
                        ...prev,
                        show: false
                    }))}>{notification.button}</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Actions