import DataTable from "../../Common/DataTable/DataTable";
import {useEffect, useState} from "react";
import axios from "axios";
import {Modal, Button} from 'react-bootstrap'
import AddAdminModal from "./AddAdminModal";
import ViewAdminsModal from "./ViewAdminsModal";

const Admins = () => {
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [admins, setAdmins] = useState([]);
    const [total, setTotal] = useState(0);
    const [keyword, setKeyword] = useState("");
    const [showAddAdminsModal, setShowAddAdminsModal] = useState(false);
    const [formData, setFormData] = useState({
        name: "", surname: "", username: "", country: "", city: "", email: "", phone: "", password: "",
        status: false, role: ""
    });
    const [notification, setNotification] = useState({show: false, title: "", body: "", button: ""});
    const [passwordError, setPasswordError] = useState(false);
    const [showViewAdminsModal, setShowViewAdminsModal] = useState(false);
    const [active, setActive] = useState({});
    const [action, setAction] = useState("");
    const [showDeleteAdminsModal, setShowDeleteAdminsModal] = useState(false);

    const headers = ["Username", "Name", "Surname", "Country", "Phone", ""];
    const showParams = ["username", "name", "surname", "country", "phone"];

    const actions = [
        {
            name: "Add new",
            action: () => {
                setFormData({
                    name: "", surname: "", username: "", country: "", city: "", email: "", phone: "", password: "",
                    status: false, role: ""
                });
                setShowAddAdminsModal(true);
                setAction("add");
            }
        }
    ];

    const rowActions = [
        {
            name: "View",
            id: 1002,
            action: () => {
                setShowViewAdminsModal(true)
            }
        },
        {
            name: "Edit",
            id: 1001,
            action: (act) => {
                setShowAddAdminsModal(true);
                setAction("edit");
                setFormData({
                    name: act.name, surname: act.surname, username: act.username, country: act.country, city: act.city, email: act.email,
                    phone: act.phone, adminName: act.adminName, role: act.role, status: act.status
                });
            }
        },
        {
            name: "Delete",
            id: 1003,
            action: () => {
                setShowDeleteAdminsModal(true)
            }
        }
    ]

    const handlers = {
        search(keyword){
            setKeyword(keyword);
        },
        async addNewAdmin(){
            if(formData.password.length < 7){
                setPasswordError(true)
            }else{
                try{
                    const response = await axios.post('/api/admin', formData);
                    setNotification({show: true, title: "Success", body: "Admin created successfully", button: "Ok"});
                    setShowAddAdminsModal(false);
                    const data = [{
                        id: response.data.id, name: formData.name, surname: formData.surname, username: formData.username, country: formData.country,
                        city: formData.city,  email: formData.email, phone: formData.phone, status: formData.status, role: formData.role
                    }, ...Admins];
                    setAdmins([...data]);
                }catch (err){
                    setNotification({show: true, title: "An error has been detected", body: "Something went wrong", button: "Ok"})
                }
            }
        },
        async editAdmin(){
            try{
                const response = await axios.put(`/api/admin/${active.id}`, formData);
                const data = admins.map((u) => {
                    if(u.id === active.id){
                        return {id: u.id, ...formData}
                    }else{
                        return {...u}
                    }
                })
                setAdmins([...data]);
                setNotification({show: true, title: "Success", body: "Admin updated successfully", button: "Ok"});
                setShowAddAdminsModal(false)
            }catch (err){
                setNotification({show: true, title: "An error has been detected", body: "Something went wrong", button: "Ok"})
            }
        },
        async deleteAdmin(){
            try {
                const response = await axios.delete(`/api/admin/${active.id}`);
                const data = admins.filter((u) => u.id !== active.id);
                setAdmins([...data]);
                setShowDeleteAdminsModal(false);
                setNotification({show: true, title: "Success", body: "Admin removed successfully", button: "Ok"});
            }catch (err){
                setShowDeleteAdminsModal(false);
                setNotification({show: true, title: "An error has been detected", body: "Something went wrong", button: "Ok"})
            }
        }
    };

    useEffect(() => {
        axios(`/api/admins?page=${page}&limit=${limit}&keyword=${keyword}`)
            .then((response) => {
                const data = response.data.data;
                setAdmins([...data]);
                setTotal(response.data.total)
            })
            .catch((err) => {

            })
    }, [limit, page, keyword])

    return(
        <>
            <DataTable
                withoutSearchButton={true}
                headers={headers}
                searchHandler={handlers.search}
                title={"Admins"}
                actionButtons={actions}
                limit={limit}
                setLimit={setLimit}
                limitControl={true}
                data={admins}
                showParams={showParams}
                pageChanged={page}
                setPageChanged={setPage}
                total={total}
                rowActions={true}
                rowActionsList={rowActions}
                setActiveRow={setActive}
            />
            {showAddAdminsModal &&
                <AddAdminModal show={showAddAdminsModal} setShow={setShowAddAdminsModal} action={action} formData={formData}
                             setFormData={setFormData} onSubmit={action === 'add' ? handlers.addNewAdmin : handlers.editAdmin} passwordError={passwordError}/>}
            {showViewAdminsModal && <ViewAdminsModal data={active} show={showViewAdminsModal} hide={setShowViewAdminsModal}/>}
            <Modal show={notification.show} onHide={() => setNotification((prev) => ({...prev, show: false}))}>
                <Modal.Header><h4>{notification.title}</h4></Modal.Header>
                <Modal.Body>{notification.body}</Modal.Body>
                <Modal.Footer>
                    <button className={"blue-button"} onClick={() => setNotification((prev) => ({
                        ...prev,
                        show: false
                    }))}>{notification.button}</button>
                </Modal.Footer>
            </Modal>
            <Modal show={showDeleteAdminsModal} onHide={() => setShowDeleteAdminsModal(false)}>
                <Modal.Header><h4>Delete user</h4></Modal.Header>
                <Modal.Body>Do you want to process?</Modal.Body>
                <Modal.Footer>
                    <Button variant={"danger"} onClick={handlers.deleteAdmin}>Yes</Button>
                    <button className={"blue-button"} onClick={() => setShowDeleteAdminsModal(false)}>No</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Admins